@import "../../variables.modules";



.green {
  color: $green;
}

.box{
  box-shadow: 0 1rem 2rem hsl(0 0% 0% / 20%);
  
  background: hsl(100 0% 100%);
  color: hsl(100 50% 20%);
  line-height: 1.5;
  font-size: 1.0rem;
  // font-weight: 300;
  width: 60vmin;
  height: 70vmin;
  // display: flex;
  // place-items: center;
  // text-align: center;
  padding: 3ch;
  border-radius: 2ch;
  border: 1px solid hsl(0 0% 83%);
  box-shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%);
}

.skills {
  columns: 1;
  @media only screen and (min-width: 940px) {
    columns: 2;
  }

  li {
    margin: 0;
    line-height: 1.75;
  }
}

